@import "general/colors";
@import "general/newTypography";
.new-yellow-area {
    background-color: $new-yellow;
    padding: 1.5em 0;
    .cards {
        display: flex;
        gap: 10em;
        justify-content: center;
        flex-wrap: wrap;
        @media screen and (max-width: 1250px) {
            gap: 5em;
        }
        @media screen and (max-width: 700px) {
            gap: 1.5em;
        }
        .card-yellow-area {
            display: flex;
            align-items: center;
            gap: 1em;
            max-width: 255px;
            .icon {
                width: 90px;
                flex: none;
            }
            .text {
                font-size: 16px;
                font-weight: normal;
            }
        }
    }
}